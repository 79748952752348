<template>
  <Combobox v-model="currentOption" :disabled="disabled">
    <div class="relative">
      <div
        class="relative inline-flex w-full cursor-default gap-4 overflow-hidden"
      >
        <!-- Open dropdown on click: https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969 -->
        <ComboboxButton as="div" class="flex-1">
          <ComboboxInput
            class="h-10 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            :class="{
              'pointer-events-none; bg-gray-100 !text-gray-300': disabled,
            }"
            @change="query = $event.target.value"
            @keyup.enter="() => (query = '')"
            @keyup.esc="() => (query = '')"
            :placeholder="placeholder"
            :displayValue="displayValue"
            autocomplete="off"
            :onBlur="handleBlur"
          />
        </ComboboxButton>
        <slot name="afterInput"></slot>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ComboboxOptions
          class="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <!-- Empty state -->
          <FormAutocompleteItem
            v-if="showEmpty && filteredOptions.length === 0"
          >
            Nothing found.
          </FormAutocompleteItem>
          <!-- Options -->
          <FormAutocompleteOption
            v-for="option in filteredOptions"
            :key="getKey(option)"
            :value="option"
            onSelectedIcon="Check"
          >
            {{ displayValue(option) }}
          </FormAutocompleteOption>
          <!-- After options slot -->
          <slot
            name="afterOptions"
            :options="filteredOptions"
            :query="query"
          ></slot>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup lang="ts" generic="T extends string | number | boolean | object">
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  TransitionRoot,
} from "@headlessui/vue";
import useNormaliseString from "~/composables/use-normalise-string";
import { autocompleteSelectedActionKey } from "~/keys/keys";

export type Key = string | number | symbol;

export interface Props<T> {
  modelValue?: T | Key;
  placeholder?: string;
  disabled?: boolean;
  options: T[];
  actions?: string[];
  getKey: (option: T) => Key;
  displayValue: (option?: unknown) => string;
  selectSingleOption?: boolean;
  showEmpty?: boolean;
  useKeyAsValue?: boolean;
}
const props = withDefaults(defineProps<Props<T>>(), {
  selectSingleOption: true,
  showEmpty: true,
  useKeyAsValue: false,
  actions: () => [],
});
const emit = defineEmits(["update:modelValue", "blur"]);

const slots = useSlots();

const keyOptionMapping = computed(() => {
  const mapping: Record<Key, T> = {};
  props.options.forEach((option) => {
    const key = props.getKey(option);
    mapping[key] = option;
  });
  return mapping;
});

const getOption = (value: T | Key | null) => {
  if (!value) return null;
  return (
    props.useKeyAsValue ? keyOptionMapping.value[value as Key] : value
  ) as T;
};

const getValue = (option?: T) => {
  if (!option) return option;
  return props.useKeyAsValue && option ? props.getKey(option) : option;
};

const selectedAction = ref();
provide(autocompleteSelectedActionKey, selectedAction);

const currentOption = computed({
  get() {
    if (props.modelValue === undefined) return null;
    return getOption(props.modelValue);
  },
  set(option) {
    if (typeof option === "string" && props.actions.includes(option))
      selectedAction.value = option;
    const parsedOption =
      option === null || !props.options.includes(option) ? undefined : option;

    emit("update:modelValue", getValue(parsedOption));
  },
});

const query = ref("");
const filterOption = (option: T) =>
  useNormaliseString(props.displayValue(option)).includes(
    useNormaliseString(query.value),
  );
const filteredOptions = computed(() => props.options.filter(filterOption));

watch(currentOption, () => {
  query.value = "";
});

watch(
  () =>
    [props.options, props.selectSingleOption, props.modelValue] as [
      T[],
      boolean,
      T,
    ],
  ([options, selectSingleOption, value]) => {
    const option = getOption(value);
    if (!option || !options.includes(option)) {
      // if the current option is empty or invalid
      if (selectSingleOption && options.length === 1) {
        currentOption.value = options[0];
      } else {
        currentOption.value = null;
      }
    }
  },
  { immediate: true },
);

const handleBlur = () => {
  setTimeout(() => {
    query.value = "";
    emit("blur");
  }, 200);
};
</script>
