<template>
  <button
    type="button"
    class="inline-flex h-10 select-none items-center justify-center gap-x-1.5 rounded-md px-4 py-1.5 text-sm font-semibold ring-1 ring-inset"
    :class="[!disabled ? buttonStyle : disabledButtonStyle]"
  >
    <BaseIcon
      v-if="leadingIcon"
      class="-mx-0.5 h-5 w-5 shrink-0"
      :class="iconClass"
      :name="leadingIcon"
    ></BaseIcon>
    <span class="truncate" v-if="slots.default">
      <slot></slot>
    </span>
    <BaseIcon
      v-if="trailingIcon"
      class="-mr-0.5 h-5 w-5 shrink-0"
      :class="iconClass"
      :name="trailingIcon"
    ></BaseIcon>
  </button>
</template>

<script setup lang="ts">
import type { IconName } from "~/types";
import { useSlots } from "vue";

export interface Props {
  leadingIcon?: IconName;
  trailingIcon?: IconName;
  disabled?: boolean;
  iconClass?: string;
  type?: "normal" | "info" | "danger" | "warning";
}

const props = withDefaults(defineProps<Props>(), {
  type: "normal",
});

const buttonStyle = computed(() => {
  return {
    "bg-white hover:bg-gray-50 active:bg-gray-100 text-gray-900 ring-gray-300":
      props.type === "normal",
    "bg-blue-50 text-blue-600 hover:bg-blue-100 active:bg-blue-200 ring-blue-500":
      props.type === "info",
    "bg-red-50 text-red-600 hover:bg-red-100 active:bg-red-200 ring-red-300":
      props.type === "danger",
    "bg-yellow-50 text-yellow-600 hover:bg-yellow-100 active:bg-yellow-200 ring-yellow-500":
      props.type === "warning",
  };
});

const disabledButtonStyle = computed(() => {
  return {
    "pointer-events-none hover:cursor-default": true,
    "bg-white/30 text-gray-400 ring-gray-200": props.type === "normal",
    "bg-blue-50/30 text-blue-300 ring-blue-200": props.type === "info",
    "bg-red-50/30 text-red-300 ring-red-200": props.type === "danger",
    "bg-yellow-50/30 text-yellow-300 ring-yellow-200": props.type === "warning",
  };
});

const slots = useSlots();
</script>
