<template>
  <SwitchGroup as="div">
    <SwitchLabel
      as="label"
      class="block text-sm font-medium leading-6 text-gray-900"
      :for="id"
    >
      <slot></slot>
    </SwitchLabel>
    <Switch
      as="button"
      v-model="enabled"
      :id="id"
      class="relative mt-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
      :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200']"
    >
      <span
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
      />
    </Switch>
  </SwitchGroup>
</template>

<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export interface Props {
  modelValue?: boolean;
  id?: string;
}
const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

const enabled = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
