import Keycloak, { type KeycloakProfile } from "keycloak-js";

export default defineStore("auth", () => {
  const config = useRuntimeConfig();
  const url = config.public.keycloakUrl;
  const realm = config.public.keycloakRealm;
  const clientId = config.public.keycloakClientId;

  const profile = ref<KeycloakProfile>();

  const keycloak = new Keycloak({
    url,
    realm,
    clientId,
  });

  const profileUrl = computed(() => `${url}/realms/${realm}/account`);

  const logout = async () => {
    await keycloak.logout();
  };

  const login = async () => {
    await keycloak.login();
  };

  const init = async () => {
    try {
      keycloak.onTokenExpired = refreshToken;
      await keycloak.init({
        onLoad: "login-required",
        responseMode: "query", // Prevents warning in Nuxt
      });
      profile.value = await keycloak.loadUserProfile();
    } catch {
      await keycloak.login();
    }
  };

  const authenticate = async () => {
    if (!keycloak.authenticated) await init();
  };

  const refreshToken = async () => {
    try {
      await keycloak.updateToken(30);
      console.log(keycloak.token);
    } catch {
      await keycloak.login();
    }
  };

  const getToken = () => keycloak.token;

  const idpProvider = () => keycloak.tokenParsed?.identity_provider;

  return {
    profile,
    profileUrl,
    logout,
    login,
    authenticate,
    getToken,
    idpProvider,
  };
});
