<template>
  <RadioGroup v-model="currentOption" as="div">
    <RadioGroupLabel
      as="label"
      class="block text-sm font-medium leading-6 text-gray-900"
      :for="id"
    >
      <slot></slot>
    </RadioGroupLabel>
    <div
      class="relative inline-flex w-full cursor-pointer select-none items-center justify-center overflow-hidden rounded-md bg-white text-gray-900 shadow-sm ring-1 ring-gray-300"
    >
      <RadioGroupOption
        v-for="(option, index) in options"
        :id="id"
        :key="getKey(option)"
        :value="option"
        class="w-full"
      >
        <div
          class="flex flex-1 items-center justify-center py-2.5 text-sm font-medium"
          :class="[
            isChecked(option)
              ? 'bg-indigo-100 text-indigo-600 transition duration-200 ease-in-out'
              : 'hover:bg-indigo-50 hover:text-indigo-600',
          ]"
        >
          <BaseIcon
            v-if="icons[index]"
            class="-ml-0.5 mr-1 h-4 w-4"
            :name="icons[index]"
          ></BaseIcon>
          <span class="truncate">
            {{ displayValue(option) }}
          </span>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup lang="ts" generic="T extends string | number | boolean | object">
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import type { IconName } from "~/types";

export type Key = string | number | symbol;

export interface Props<T> {
  modelValue?: T | Key;
  id?: string;
  options: T[];
  getKey: (option: T) => Key;
  displayValue: (option: T) => string;
  useKeyAsValue?: boolean;
  icons?: IconName[];
}
const props = withDefaults(defineProps<Props<T>>(), {
  useKeyAsValue: false,
  icons: () => [],
});
const emit = defineEmits(["update:modelValue"]);

const keyOptionMapping = computed(() => {
  const mapping: Record<Key, T> = {};
  props.options.forEach((option) => {
    const key = props.getKey(option);
    mapping[key] = option;
  });
  return mapping;
});

const getOption = (value: T | Key | undefined) => {
  if (!value) return null;
  return (
    props.useKeyAsValue ? keyOptionMapping.value[value as Key] : value
  ) as T;
};

const getValue = (option?: T) => {
  if (!option) return option;
  return props.useKeyAsValue && option ? props.getKey(option) : option;
};

const currentOption = computed({
  get() {
    return getOption(props.modelValue);
  },
  set(option) {
    const parsedOption =
      option === null || !props.options.includes(option) ? undefined : option;
    emit("update:modelValue", getValue(parsedOption));
  },
});

const isChecked = (option: T) =>
  currentOption.value &&
  props.getKey(currentOption.value) === props.getKey(option);
</script>
