<template>
  <div
    v-bind="getRootProps()"
    class="dropzone group w-full select-none rounded-md border-0 bg-opacity-40 px-2 py-4 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:ring-indigo-200"
    :class="{
      'cursor-pointer': !isDragActive,
      '!bg-indigo-50 ring-2 ring-inset !ring-indigo-600': focus || isDragActive,
      'pointer-events-none bg-gray-100 !text-gray-300': disabled,
    }"
  >
    <input :id="id" v-bind="getInputProps()" />
    <div class="flex flex-col items-center">
      <BaseIcon name="FileUp" class="h-16 w-16 text-gray-700" />
      <p v-if="!isDragActive">
        <span class="font-semibold">Click here</span>
        or
        <span class="font-semibold">drag & drop</span>
        to upload files
      </p>
      <p class="font-semibold" v-else>Drop files here</p>
    </div>
    <!-- List files -->
    <ul
      class="mt-3 flex flex-col gap-1 px-4 text-sm font-semibold"
      v-if="files"
    >
      <li
        v-for="(file, index) in files"
        :key="index"
        :title="file.name"
        class="flex w-full flex-col gap-1 overflow-hidden rounded-md border-0 bg-gray-50 px-4 py-2 ring-1 ring-inset ring-gray-300 group-hover:bg-white"
      >
        <div class="flex flex-row justify-between">
          <div class="flex w-full flex-row items-center overflow-hidden">
            <span class="mr-2 text-gray-500" v-if="files.length > 1">
              {{ index + 1 }}.
            </span>
            <div class="w-full">
              <slot name="file" :file="file" :index="index" :files="files">
                <span class="block truncate">{{ file.name }}</span>
              </slot>
            </div>
          </div>
          <BaseIconButton
            icon="X"
            title="Remove file"
            icon-class="!h-4 !w-4 "
            @click.stop="removeFile(index)"
          />
        </div>
        <slot :file="file" :index="index" :files="files"></slot>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useDropzone } from "vue3-dropzone";
import type { FileUploadOptions } from "vue3-dropzone";

export interface Props {
  modelValue: File[];
  options: Omit<FileUploadOptions, "onDrop" | "onFileDialogCancel">;
  id?: string;
  disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});
const emit = defineEmits(["update:modelValue", "blur"]);

const files = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const focus = computed(() => isFileDialogActive.value || isFocused.value);

const addFiles = (newFiles: File[]) => {
  if (props.disabled) return;
  files.value.push(...newFiles);
};

const removeFile = (index: number) => {
  if (props.disabled) return;
  files.value.splice(index, 1);
};

const onDrop = (acceptedFiles: any[]) => {
  addFiles(acceptedFiles);
  emit("blur");
};

const onFileDialogCancel = () => {
  emit("blur");
};

const {
  getRootProps,
  getInputProps,
  isDragActive,
  isFileDialogActive,
  isFocused,
} = useDropzone({ ...props.options, onFileDialogCancel, onDrop });
</script>
