<template>
  <FormDropzone
    :id="id"
    :options="options"
    :disabled="disabled"
    v-model="value"
    @blur="handleBlur"
  >
    <template
      v-for="(slot, slotName) in slots"
      :key="slotName"
      #[slotName]="data"
    >
      <component :is="slot" v-bind="data" />
    </template>
  </FormDropzone>
</template>

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";

const props = defineProps({
  context: Object,
});
const { id, dropOptions: options, disabled, slots } = toRefs(props.context!);

const value = computed<File[]>({
  get: () => {
    return props.context?._value;
  },
  set: (value) => {
    props.context?.node.input(value);
  },
});

const node: FormKitNode = props.context?.node;

const handleBlur = () => {
  node.context?.handlers.blur();
};
</script>

<style lang="postcss">
[data-type="dropzone"][data-invalid] .dropzone {
  @apply ring-red-600;
}
</style>
