import { createInput } from "@formkit/vue";
import type { DefaultConfigOptions } from "@formkit/vue";
import { generateClasses } from "@formkit/themes";
import FormkitSwitch from "@/components/formkit/switch.vue";
import FormRadio from "@/components/formkit/radio.vue";
import FormkitAutocomplete from "@/components/formkit/autocomplete.vue";
import FormkitDropzone from "@/components/formkit/dropzone.vue";
import FormkitDisplayValue from "@/components/formkit/display-value.vue";
import {
  createAutoAnimatePlugin,
  createMultiStepPlugin,
} from "@formkit/addons";
import "@formkit/addons/css/multistep";

const config: DefaultConfigOptions = {
  plugins: [
    createAutoAnimatePlugin({ duration: 100, easing: "ease-in-out" }),
    createMultiStepPlugin(),
  ],
  inputs: {
    switch: createInput(FormkitSwitch),
    fancyRadio: createInput(FormRadio, {
      props: [
        "id",
        "choices",
        "getKey",
        "displayValue",
        "useKeyAsValue",
        "icons",
      ],
    }),
    autocomplete: createInput(FormkitAutocomplete, {
      props: [
        "placeholder",
        "disabled",
        "choices",
        "getKey",
        "displayValue",
        "selectSingleOption",
        "showEmpty",
        "useKeyAsValue",
        "actions",
      ],
    }),
    dropzone: createInput(FormkitDropzone, {
      props: ["id", "dropOptions", "disabled"],
    }),
    displayValue: createInput(FormkitDisplayValue),
  },
  config: {
    classes: generateClasses({
      global: {
        label: "text-sm font-medium leading-6 text-gray-900",
        legend: "text-sm font-medium leading-4 text-gray-900",
        help: "text-sm leading-4 text-gray-600",
        message:
          "text-sm text-gray-600 leading-4 formkit-invalid:text-red-600 data-[message-type='validation']:text-red-600 data-[message-type='ui']:text-red-600",
        outer: "formkit-disabled:opacity-40",
      },
      "family:text": {
        input:
          "h-10 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 formkit-disabled:bg-gray-100 formkit-disabled:text-gray-300 formkit-disabled:pointer-events-none formkit-invalid:ring-red-600",
      },
      "family:button": {
        input:
          "$reset inline-flex h-10 select-none items-center justify-center gap-x-1.5 rounded-md bg-indigo-50 px-4 py-1.5 text-sm font-semibold ring-1 ring-inset text-indigo-600 shadow-sm ring-indigo-300 hover:bg-indigo-100 active:bg-indigo-200 formkit-disabled:pointer-events-none formkit-disabled:text-indigo-300 formkit-disabled:ring-indigo-200 formkit-disabled:hover:cursor-default",
      },
      radio: {
        wrapper: "flex flex-row",
        input: "$reset ring-gray-300 border-gray-300 !border-1 !ring-0",
        label: "ml-2 leading-6",
      },
      checkbox: {
        wrapper: "flex flex-row",
        input: "$reset border-gray-300 rounded-sm",
        label: "ml-2 leading-6",
        help: "mt-1",
        messages: "mt-1",
        options: "mt-2",
      },
      select: {
        input:
          "h-10 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 formkit-disabled:bg-gray-100 formkit-disabled:text-gray-300 formkit-disabled:pointer-events-none formkit-invalid:ring-red-600",
      },
      textarea: {
        input:
          "$reset block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 formkit-disabled:bg-gray-100 formkit-disabled:text-gray-300 formkit-disabled:pointer-events-none formkit-invalid:ring-red-600",
      },
      dropzone: {
        help: "mt-1",
      },
      "multi-step": {
        outer:
          "!w-full !rounded-md !border-0 !ring-1 !ring-inset !ring-gray-300 !shadow-sm overflow-clip",
        wrapper: "!max-w-full ",
        steps: "!py-8 !px-4 sm:!px-12 md:!px-16 bg-white",
        tabs: "!rounded-none !shadow-sm",
      },
    }),
  },
};

export default config;
