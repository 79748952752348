<template>
  <FormAutocomplete
    :id="id"
    :placeholder="placeholder"
    :disabled="disabled"
    :options="options"
    :getKey="getKey"
    :displayValue="displayValue"
    :selectSingleOption="selectSingleOption"
    :showEmpty="showEmpty"
    :useKeyAsValue="useKeyAsValue"
    :actions="actions"
    v-model="value"
    @blur="handleBlur"
  >
    <template
      v-for="(slot, slotName) in slots"
      :key="slotName"
      #[slotName]="data"
    >
      <component :is="slot" v-bind="data" />
    </template>
  </FormAutocomplete>
</template>

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";

const props = defineProps({
  context: Object,
});
const {
  id,
  placeholder,
  disabled,
  choices: options,
  getKey,
  displayValue,
  selectSingleOption,
  showEmpty,
  useKeyAsValue,
  actions,
  slots,
} = toRefs(props.context!);

const value = computed({
  get: () => {
    return props.context?._value;
  },
  set: (value) => {
    props.context?.node.input(value);
  },
});

const node: FormKitNode = props.context?.node;

const handleBlur = () => {
  node.context?.handlers.blur();
};
</script>

<style lang="postcss">
[data-type="autocomplete"][data-invalid] input {
  @apply ring-red-600;
}
</style>
