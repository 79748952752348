<template>
  <FormRadio
    v-model="value"
    :id="id"
    :options="options"
    :getKey="getKey"
    :displayValue="displayValue"
    :useKeyAsValue="useKeyAsValue"
    :icons="icons"
  >
  </FormRadio>
</template>

<script setup lang="ts">
const props = defineProps({
  context: Object,
});
const {
  id,
  choices: options,
  getKey,
  displayValue,
  useKeyAsValue,
  icons,
} = toRefs(props.context!);

const value = computed({
  get: () => {
    return props.context?._value;
  },
  set: (value) => {
    props.context?.node.input(value);
  },
});
</script>
