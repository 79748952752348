import { default as about_ushiAu7QDVhRMeta } from "/frontend/pages/about_us.vue?macro=true";
import { default as _91id_93tlpYDXdtSUMeta } from "/frontend/pages/acquisitions/[id].vue?macro=true";
import { default as index2mU8R1beFJMeta } from "/frontend/pages/acquisitions/index.vue?macro=true";
import { default as indexzh70oVUhJfMeta } from "/frontend/pages/index.vue?macro=true";
import { default as index2xcycnI7wgMeta } from "/frontend/pages/live/index.vue?macro=true";
import { default as _91id_93yrGgNQxQNvMeta } from "/frontend/pages/payload_configs/[id].vue?macro=true";
import { default as indexc9EI4UGEpfMeta } from "/frontend/pages/payload_configs/index.vue?macro=true";
import { default as newXQ0ibSkuECMeta } from "/frontend/pages/payload_configs/new.vue?macro=true";
import { default as profile5LcdvX4wJBMeta } from "/frontend/pages/profile.vue?macro=true";
import { default as _91id_93DmNtcDAuLXMeta } from "/frontend/pages/satellite_groups/[id].vue?macro=true";
import { default as newvpTXHrAVUyMeta } from "/frontend/pages/satellite_groups/new.vue?macro=true";
import { default as _91id_93un13WE66dSMeta } from "/frontend/pages/satellites/[id].vue?macro=true";
import { default as indexAEb4eWzI8mMeta } from "/frontend/pages/satellites/index.vue?macro=true";
import { default as new1M7IfwjZQHMeta } from "/frontend/pages/satellites/new.vue?macro=true";
import { default as _91id_93MMaSRP4kHPMeta } from "/frontend/pages/targets/[id].vue?macro=true";
import { default as newunNTFqB1SxMeta } from "/frontend/pages/targets/new.vue?macro=true";
import { default as targets7epwaPW55qMeta } from "/frontend/pages/targets.vue?macro=true";
import { default as _91id_93qdPGbZbz3ZMeta } from "/frontend/pages/tasks/[id].vue?macro=true";
import { default as indexwTMhxSosG9Meta } from "/frontend/pages/tasks/index.vue?macro=true";
import { default as newpJtsOX9EtJMeta } from "/frontend/pages/tasks/new.vue?macro=true";
export default [
  {
    name: "about_us",
    path: "/about_us",
    component: () => import("/frontend/pages/about_us.vue").then(m => m.default || m)
  },
  {
    name: "acquisitions-id",
    path: "/acquisitions/:id()",
    component: () => import("/frontend/pages/acquisitions/[id].vue").then(m => m.default || m)
  },
  {
    name: "acquisitions",
    path: "/acquisitions",
    component: () => import("/frontend/pages/acquisitions/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    redirect: "/targets",
    component: () => import("/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "live",
    path: "/live",
    component: () => import("/frontend/pages/live/index.vue").then(m => m.default || m)
  },
  {
    name: "payload_configs-id",
    path: "/payload_configs/:id()",
    component: () => import("/frontend/pages/payload_configs/[id].vue").then(m => m.default || m)
  },
  {
    name: "payload_configs",
    path: "/payload_configs",
    component: () => import("/frontend/pages/payload_configs/index.vue").then(m => m.default || m)
  },
  {
    name: "payload_configs-new",
    path: "/payload_configs/new",
    component: () => import("/frontend/pages/payload_configs/new.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/frontend/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "satellite_groups-id",
    path: "/satellite_groups/:id()",
    component: () => import("/frontend/pages/satellite_groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "satellite_groups-new",
    path: "/satellite_groups/new",
    component: () => import("/frontend/pages/satellite_groups/new.vue").then(m => m.default || m)
  },
  {
    name: "satellites-id",
    path: "/satellites/:id()",
    component: () => import("/frontend/pages/satellites/[id].vue").then(m => m.default || m)
  },
  {
    name: "satellites",
    path: "/satellites",
    component: () => import("/frontend/pages/satellites/index.vue").then(m => m.default || m)
  },
  {
    name: "satellites-new",
    path: "/satellites/new",
    component: () => import("/frontend/pages/satellites/new.vue").then(m => m.default || m)
  },
  {
    name: "targets",
    path: "/targets",
    component: () => import("/frontend/pages/targets.vue").then(m => m.default || m),
    children: [
  {
    name: "targets-id",
    path: ":id()",
    meta: _91id_93MMaSRP4kHPMeta || {},
    component: () => import("/frontend/pages/targets/[id].vue").then(m => m.default || m)
  },
  {
    name: "targets-new",
    path: "new",
    component: () => import("/frontend/pages/targets/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    meta: _91id_93qdPGbZbz3ZMeta || {},
    component: () => import("/frontend/pages/tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/frontend/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "tasks-new",
    path: "/tasks/new",
    component: () => import("/frontend/pages/tasks/new.vue").then(m => m.default || m)
  }
]