<template>
  <div class="h-full">
    <NuxtErrorBoundary @error="handleError">
      <template #error="{ error, clearError }">
        <div class="flex h-full items-center justify-center bg-gray-100">
          <div
            class="m-2 flex w-auto max-w-lg flex-col items-center gap-4 rounded-lg bg-white p-6 text-center shadow-md"
          >
            <p>An error occurred: {{ error }}</p>
            <BaseButtonSecondary @click="clearError" type="danger">
              Reload the page
            </BaseButtonSecondary>
          </div>
        </div>
      </template>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </NuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: "Tasking",
  bodyAttrs: {
    class: "bg-gray-50",
  },
  meta: [
    {
      name: "description",
      content: "Generate optimized schedules for constellations of satellites.",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0, user-scalable=no",
    },
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "theme-color", content: "#fff" },
    { name: "apple-mobile-web-app-status-bar-style", content: "default" },
  ],
  link: [
    { rel: "manifest", href: "/manifest.json" },
    { rel: "apple-touch-icon", href: "apple-touch-icon.png" },
  ],
});

const handleError = (err: any) => {
  // Possibly link to Sentry here.
  console.error(err);
};

const authStore = useAuthStore();
const { authenticate } = authStore;

await authenticate();
</script>
