<template>
  <FormSwitch :id="id" v-model="value" />
</template>

<script setup lang="ts">
const props = defineProps({
  context: Object,
});

const { id } = toRefs(props.context!);

const value = computed({
  get: () => {
    return props.context?._value;
  },
  set: (value) => {
    props.context?.node.input(value);
  },
});
</script>
