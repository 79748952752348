<template>
  <component :is="icon"></component>
</template>

<script setup lang="ts">
import * as icons from "lucide-vue-next";
import type { IconName } from "~/types";

export interface Props {
  name: IconName;
}

const props = defineProps<Props>();

const icon = computed(() => {
  return icons[`${props.name}`];
});
</script>
