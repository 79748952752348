<template>
  <span>{{ value }}</span>
</template>

<script setup lang="ts">
const props = defineProps({
  context: Object,
});

const value = computed({
  get: () => {
    return props.context?._value;
  },
  set: (value) => {
    props.context?.node.input(value);
  },
});
</script>
